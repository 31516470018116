import styled from '@emotion/styled'
import React from 'react'
import useRouteParam from '../../hooks/useRouteParam'
import ColumnBtnList from '../atoms/columnBtnList'

const Wrapper = styled.article`
  position: relative;

  .p-list-link {
    position: absolute;
    top: 9px;
    right: 2px;

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 5px;
      left: -14px;
      border-left: 8px solid #3ec7b3;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

/**
 * コラム全記事一覧リストコンポーネント
 * @constructor
 */
const ColumnAllLists = () => {
  const routeParam = useRouteParam('/column/details/C0091/')
  const routeParam2 = useRouteParam('/column/details/C0090/')
  const routeParam3 = useRouteParam('/column/details/C0089/')
  const routeParam4 = useRouteParam('/column/details/C0088/')
  const routeParam5 = useRouteParam('/column/details/C0073/')
  const routeParam6 = useRouteParam('/column/details/C0072/')
  const routeParam7 = useRouteParam('/column/details/C0062/')
  const routeParam8 = useRouteParam('/column/details/C0071/')
  const routeParam9 = useRouteParam('/column/details/C0070/')
  const routeParam10 = useRouteParam('/column/details/C0061/')
  const routeParam11 = useRouteParam('/column/details/C0060/')
  const routeParam12 = useRouteParam('/column/details/C0069/')
  const routeParam13 = useRouteParam('/column/details/C0068/')
  const routeParam14 = useRouteParam('/column/details/C0059/')
  const routeParam15 = useRouteParam('/column/details/C0067/')
  const routeParam16 = useRouteParam('/column/details/C0058/')
  const routeParam17 = useRouteParam('/column/details/C0057/')
  const routeParam18 = useRouteParam('/column/details/C0056/')
  const routeParam19 = useRouteParam('/column/details/C0055/')
  const routeParam20 = useRouteParam('/column/details/C0054/')
  const routeParam21 = useRouteParam('/column/details/C0053/')
  const routeParam22 = useRouteParam('/column/details/C0052/')
  const routeParam23 = useRouteParam('/column/details/C0051/')
  const routeParam24 = useRouteParam('/column/details/C0050/')
  const routeParam25 = useRouteParam('/column/details/C0049/')
  const routeParam26 = useRouteParam('/column/details/C0048/')
  const routeParam27 = useRouteParam('/column/details/C0047/')
  const routeParam28 = useRouteParam('/column/details/C0046/')
  const routeParam29 = useRouteParam('/column/details/C0045/')
  const routeParam30 = useRouteParam('/column/details/C0044/')
  const routeParam31 = useRouteParam('/column/details/C0043/')
  const routeParam32 = useRouteParam('/column/details/C0041/')
  const routeParam33 = useRouteParam('/column/details/C0040/')
  const routeParam34 = useRouteParam('/column/details/C0035/')
  const routeParam35 = useRouteParam('/column/details/C0037/')
  const routeParam36 = useRouteParam('/column/details/C0036/')
  const routeParam37 = useRouteParam('/column/details/C0039/')
  const routeParam38 = useRouteParam('/column/details/C0038/')
  const routeParam39 = useRouteParam('/column/details/C0042/')
  const routeParam40 = useRouteParam('/column/details/C0034/')
  const routeParam41 = useRouteParam('/column/details/C0033/')
  const routeParam42 = useRouteParam('/column/details/C0032/')
  const routeParam43 = useRouteParam('/column/details/C0031/')
  const routeParam44 = useRouteParam('/column/details/C0001/')
  const routeParam45 = useRouteParam('/column/details/C0002/')
  const routeParam46 = useRouteParam('/column/details/C0003/')
  const routeParam47 = useRouteParam('/column/details/C0004/')
  const routeParam48 = useRouteParam('/column/details/C0005/')
  const routeParam49 = useRouteParam('/column/details/C0006/')
  const routeParam50 = useRouteParam('/column/details/C0007/')
  const routeParam51 = useRouteParam('/column/details/C0008/')
  const routeParam52 = useRouteParam('/column/details/C0009/')
  const routeParam53 = useRouteParam('/column/details/C0010/')
  const routeParam54 = useRouteParam('/column/details/C0011/')
  const routeParam55 = useRouteParam('/column/details/C0012/')
  const routeParam56 = useRouteParam('/column/details/C0013/')
  const routeParam57 = useRouteParam('/column/details/C0014/')
  const routeParam58 = useRouteParam('/column/details/C0015/')
  const routeParam59 = useRouteParam('/column/details/C0016/')
  const routeParam60 = useRouteParam('/column/details/C0017/')
  const routeParam61 = useRouteParam('/column/details/C0018/')
  const routeParam62 = useRouteParam('/column/details/C0019/')
  const routeParam63 = useRouteParam('/column/details/C0020/')
  const routeParam64 = useRouteParam('/column/details/C0021/')
  const routeParam65 = useRouteParam('/column/details/C0022/')
  const routeParam66 = useRouteParam('/column/details/C0023/')
  const routeParam67 = useRouteParam('/column/details/C0024/')
  const routeParam68 = useRouteParam('/column/details/C0025/')
  const routeParam69 = useRouteParam('/column/details/C0026/')
  const routeParam70 = useRouteParam('/column/details/C0027/')
  const routeParam71 = useRouteParam('/column/details/C0028/')
  const routeParam72 = useRouteParam('/column/details/C0029/')
  const routeParam73 = useRouteParam('/column/details/C0030/')
  const routeParam74 = useRouteParam('/column/details/C0092/')
  const routeParam75 = useRouteParam('/column/details/C0093/')
  const routeParam76 = useRouteParam('/column/details/C0094/')
  const routeParam77 = useRouteParam('/column/details/C0095/')
  const routeParam78 = useRouteParam('/column/details/C0096/')
  const routeParam79 = useRouteParam('/column/details/C0097/')
  const routeParam80 = useRouteParam('/column/details/C0063/')
  const routeParam81 = useRouteParam('/column/details/C0064/')
  const routeParam82 = useRouteParam('/column/details/C0065/')
  const routeParam83 = useRouteParam('/column/details/C0066/')

  return (
    <Wrapper>
      <section>
        <ColumnBtnList
          href={routeParam83}
          imgId="c0066.jpg"
          label="ハイブリッドワークとは？メリットやデメリット、成功させるポイントについて解説！"
          text="多様な働き方が尊重される時代が到来しており、法律に明記のあるフレックスタイム制や裁量労働制等だけでなく、法律に明記はないものの、徐々に採用されつつある「ハイブリッドワーク」にも注目が集まっています。今回はハイブリッドワークのメリットデメリットの他に成功させるポイントについても解説します。"
          date="2024.08.19"
        />
        <ColumnBtnList
          href={routeParam82}
          imgId="c0065.jpg"
          label="年次有給休暇管理簿とは？対象者、罰則、作成方法、保存期間などについて解説"
          text="2019年4月1日以後、企業規模を問わず、年10日以上有給休暇を付与される労働者に対して付与された日から1年以内に5日の時季指定が義務化されています。この時季指定義務と併せて「年次有給休暇管理簿」の作成も義務付"
          date="2024.07.26"
        />
        <ColumnBtnList
          href={routeParam81}
          imgId="c0064.jpg"
          label="コロナ禍で変わった労働環境。有給取得率や残業は？"
          text="コロナが本格化し出した2020年から3年の時を経て、労務管理手法については大きな変化が見られました。労務管理手法の変化は我が国の最も多くを占める被雇用者に対しても直接的に影響が及ぶ部分で被雇用者の対応に"
          date="2024.07.05"
        />
        <ColumnBtnList
          href={routeParam80}
          imgId="c0063.jpg"
          label="勤怠管理と給与計算の連携について。なぜ連携した方がいいの？留意すべきポイントとは"
          text="一定以上の規模になると勤怠管理と給与計算は密接に関連することとなります。もちろん連携しない状態であっても運用自体は可能ですが、相互に連携することで相互の事務の簡素化合理化を進めることができます。"
          date="2024.06.21"
        />
        <ColumnBtnList
          href={routeParam79}
          imgId="c0097.jpg"
          label="勤怠管理システムは自作できる？メリットやデメリット・注意点について解説"
          text="勤怠管理システムを自作している事業者の方もいるのではないでしょうか。低コストというメリットはあるものの、デメリットや注意点を理解しておかなければ後悔する恐れがあります。"
          date="2024.05.15"
        />
        <ColumnBtnList
          href={routeParam78}
          imgId="c0096.jpg"
          label="勤怠管理システムとは？導入メリットと必要機能を解説"
          text="企業規模に関わらず、勤怠管理システムを導入することにはメリットがあります。なかでも、給与計算ミスや不正防止など、人的ミス防止や業務環境の改善につながることがポイントです。"
          date="2024.04.25"
        />
        <ColumnBtnList
          href={routeParam77}
          imgId="c0095.jpg"
          label="勤怠管理と労務管理の違いとは？システムで連携させるメリットを紹介"
          text="勤怠管理と労務管理は混同されやすい業務ですが、それぞれの業務内容には違いがあります。では、具体的にどのような違いがあるのか？この記事では、勤怠管理と労務管理の違いや特徴"
          date="2024.04.18"
        />
        <ColumnBtnList
          href={routeParam76}
          imgId="c0094.jpg"
          label="勤怠管理を効率化する方法とは？効率化をする際のポイントや具体的な方法をご紹介"
          text="勤怠管理は、従業員の数が増えれば増えるほど時間と手間がかかります。非効率的な方法で管理をしていると、集計や給与計算の業務に追われ、担当者に過度な負担がかかります。"
          date="2024.04.05"
        />
        <ColumnBtnList
          href={routeParam75}
          imgId="c0093.jpg"
          label="勤怠管理の課題とは？企業が抱えやすい課題とその解決策を紹介"
          text="企業の責任者や労務担当者は、従業員の勤怠管理を適切に行う必要があります。しかし、管理すべき情報が多いため、集計作業に負担を負担を感じることもあるかもしれません。その結果、勤怠管理にミスが生じたり、"
          date="2024.03.28"
        />
        <ColumnBtnList
          href={routeParam74}
          imgId="c0092.jpg"
          label="勤怠管理のワークフローはシステム化できる！メリットや選び方を解説"
          text="勤怠管理における申請や承認、決済などの手続きに無駄があると、業務効率が下がってしまう可能性があります。このような問題を解決する方法として、ワークフロー機能が付いた勤怠管理システムの導入がおすすめです。"
          date="2024.03.21"
        />
        <ColumnBtnList
          href={routeParam}
          imgId="c0091.jpg"
          label="派遣会社に最適な勤怠管理システムとは？必要機能や導入する際の注意点を紹介"
          text="事業規模がまだ小さいうちは、Excel(エクセル)による管理でも問題はありませんが、規模が大きくなれば管理しきることは難しくなるでしょう。また、Excelによる管理の場合、派遣社員の勤怠状況をリアルタイムに把握すること"
          date="2024.02.28"
        />
        <ColumnBtnList
          href={routeParam2}
          imgId="c0090.jpg"
          label="無料で使える勤怠管理アプリとは？メリットや注意点も紹介"
          text="勤怠管理システムの導入を検討しているが、導入コストをなるべく抑えたいという企業は多いでしょう。特に、リモートワークを採用している企業では、従業員が自宅からも打刻可能なシステムを求めているのではないでしょうか。"
          date="2024.02.22"
        />
        <ColumnBtnList
          href={routeParam3}
          imgId="c0089.jpg"
          label="中小企業向け勤怠管理システムの選び方とは？おすすめシステムも紹介"
          text="Excel(エクセル)から勤怠管理システムへの移行を検討している方もいるでしょう。システムを導入することで、勤怠管理が効率化されるため、集計作業を短時間で終わらせることができるといったメリットがあります。"
          date="2024.02.16"
        />
        <ColumnBtnList
          href={routeParam4}
          imgId="c0088.jpg"
          label="勤怠管理システムの選び方や比較ポイントは？ 導入方法も紹介"
          text="自社に合わない勤怠管理システムを導入してしまうと余計なコストがかかってしまう可能性があります。そのため、自社に合ったシステムを選ぶことが重要になります。しかしながら、最適なシステム"
          date="2024.02.13"
        />
        <ColumnBtnList
          href={routeParam5}
          imgId="c0073.jpg"
          label="変形労働時間制とは？勤怠管理の効率的な方法を解説"
          text="働き方改革などの影響により、変形労働時間制を導入する企業も増えてきました。そのため、自社でも変形労働時間制の導入を検討している方も多いでしょう。"
          date="2024.01.22"
        />
        <ColumnBtnList
          href={routeParam6}
          imgId="c0072.jpg"
          label="有給管理を効率化する勤怠管理システムの選び方やお勧めのシステムをご紹介"
          text="働き方改革が推進されている現在、従業員の有給休暇の取得が多くの企業で課題として挙がってきています。特に、2019年の労働基準法の改正により、年10日以上の有給休暇が付与されている"
          date="2024.01.12"
        />
        <ColumnBtnList
          href={routeParam7}
          imgId="c0062.jpg"
          label="みなし労働時間制について。概要やメリットやデメリットなどを解説"
          text="多くの企業が採用する「固定労働時間制」とは全く別の制度として「みなし労働時間制」があります。ただし、運用方法を誤ってしまうと違法な労務管理となる場合もあることから今回はみなし労働時間制"
          date="2024.01.12"
        />
        <ColumnBtnList
          href={routeParam8}
          imgId="c0071.jpg"
          label="勤怠管理システムの費用対効果を最大限にできるコストの見直しとは？"
          text="勤怠管理システムを選ぶ際、費用は避けて通れない重要な判断基準です。無料プランから有料プランまで、各種オプションのコストとメリットを慎重に比較することが、賢明な選択につながります"
          date="2023.12.22"
        />
        <ColumnBtnList
          href={routeParam9}
          imgId="c0070.jpg"
          label="Excel(エクセル)でできる勤怠管理の限界とシステム導入で得られるメリットとは？"
          text="効率的な勤怠管理は企業運営において欠かせない要素です。Excel(エクセル)を活用すれば、コストをかけずに勤怠管理を始められますが、実は多くの落とし穴が存在します"
          date="2023.12.22"
        />
        <ColumnBtnList
          href={routeParam10}
          imgId="c0061.jpg"
          label="教員の働き方改革！働き方改革が必要な理由・進めるための課題とは？"
          text="次代の社会を担う子供達の教育および人間形成に寄与する最も近い立場にある教員においても働き方改革の必要性が挙げられています。旧来、受け持ちの授業だけでなく、部活動の指導や保護者対応等、"
          date="2023.12.08"
        />
        <ColumnBtnList
          href={routeParam11}
          imgId="c0060.jpg"
          label="運送業の働き方改革！改革までに取り組むべき内容・注意点は？"
          text="2024年4月1日から運送業においても時間外労働上限規制が適用されます。これまで運送業は業務の特殊性を勘案し、時間外労働の上限規制の適用が「猶予」されていました。"
          date="2023.11.16"
        />
        <ColumnBtnList
          href={routeParam12}
          imgId="c0069.jpg"
          label="手動での勤怠管理からの脱却！無料の勤怠管理システムのメリットとは？"
          text="無料の勤怠管理システムがあることをご存じですか？これらのツールは、給与計算やシフトの管理、時間の記録など、企業における労働管理に関するさまざまな課題を解決するのに役立ちます。"
          date="2023.11.16"
        />
        <ColumnBtnList
          href={routeParam13}
          imgId="c0068.jpg"
          label="勤怠管理システムを導入していない会社で発生するリスクとは？"
          text="労働環境の変化や法的要件の厳格化に伴い、勤怠管理システムの導入は企業にとってますます重要性が増えています。しかし、すべての会社が導入しているわけではなく、検討もできていない企業があることも"
          date="2023.11.02"
        />
        <ColumnBtnList
          href={routeParam14}
          imgId="c0059.jpg"
          label="医師の働き方改革！分類別の適用内容や取り組むべき内容・具体策は？"
          text="2019年4月1日の施行から5年の猶予期間を経て、2024年4月1日から「聖域」とされていた医師に対する時間外労働の上限規制が課せられます。一般職とは明らかに働き方が異なる職種ではあるものの、"
          date="2023.10.13"
        />
        <ColumnBtnList
          href={routeParam15}
          imgId="c0067.jpg"
          label="勤怠管理は経費精算付きのクラウドがおすすめ！ その理由とは..."
          text="勤怠管理と経費精算は、多くの企業にとって煩雑な業務です。紙での申請やExcelを使用する従来の方法は、ミスによる差し戻しなど、効率性を損なうことが多くありました。"
          date="2023.09.27"
        />
        <ColumnBtnList
          href={routeParam16}
          imgId="c0058.jpg"
          label="建設業の働き方改革！適用される内容や取り組むべきこととは？"
          text="2024年4月1日からいよいよ建設業においても時間外労働上限規制が適用されます。これまで同業種や医師については適用が「猶予」されていましたが、5年の期間を経て猶予期間が終了します。"
          date="2023.09.11"
        />
        <ColumnBtnList
          href={routeParam17}
          imgId="c0057.jpg"
          label="働き方改革推進支援助成金（適用猶予業種等対応コース）、活用すべき事業主や対象となる取り組みは？"
          text="労働基準法では、2024年４月１日より、建設業、運送業、医師等の「適用猶予業種等」に対しても時間外労働の上限規制が適用されることとなります。"
          date="2023.08.29"
        />
        <ColumnBtnList
          href={routeParam18}
          imgId="c0056.jpg"
          label="「ワークライフバランス」とは？意味やメリットなどを徹底解説！"
          text="働き方改革を契機に注目されるキーワードの1つに挙げられているのが「ワークライフバランス」です。現在、我が国は多様な働き方が尊重される時代に突入しており、「仕事一辺倒」がよしと"
          date="2023.08.10"
        />
        <ColumnBtnList
          href={routeParam19}
          imgId="c0055.jpg"
          label="所定労働時間に満たない！？その場合の賃金計算方法は？"
          text="労働時間とは、「使用者の指揮命令下に置かれた時間」と定義されていますが、定義とは別に、実務上の労働時間には大きく分けて2つの労働時間が存在します。１つは所定労働時間であり、も"
          date="2023.07.27"
        />
        <ColumnBtnList
          href={routeParam20}
          imgId="c0054.jpg"
          label="役員と有給休暇について。役員の働き方とは？"
          text="多くの企業には労働者とは別に「役員」が在籍しています。原則として役員は労働者ではありませんので、労働関係法令の適用がないことに付随して、雇用保険の資格取得ができない等の特徴"
          date="2023.07.11"
        />
        <ColumnBtnList
          href={routeParam21}
          imgId="c0053.jpg"
          label="年次有給休暇に関する事例集クイズ。いくつ正解できますか？"
          text="労働基準法の中でも労使ともに注目度の高い制度の１つに有給休暇があります。労働法制上の中で唯一、働いていないにも関わらず給与の支払い義務がある「休暇」は年次有給休暇のみです。"
          date="2023.06.28"
        />
        <ColumnBtnList
          href={routeParam22}
          imgId="c0052.jpg"
          label="有給休暇と残業時間の関係。残業時間が相殺されるケースとは"
          text="有給休暇と残業時間とは全く性質が異なるものの、相互に密接に関連している部分があります。この関係を理解できていない状態では適正な労務管理を進めていくことが難しくなります。今回"
          date="2023.06.13"
        />
        <ColumnBtnList
          href={routeParam23}
          imgId="c0051.jpg"
          label="同一労働同一賃金の原則について。メリット・デメリットを解説"
          text="同一労働同一賃金に関する法施行がされた現代においても、コロナ対応によって、同一労働同一賃金対応への優先順位が下がっている業種があることは否めません。しかし、何も対応しないと"
          date="2023.05.29"
        />
        <ColumnBtnList
          href={routeParam24}
          imgId="c0050.jpg"
          label="「これって労働時間？」曖昧な時間の取り扱いを再確認！"
          text="使用者と労働者の永遠のテーマと言っても過言ではない論点に「これは労働時間にあたるのか」という論点があります。労働時間とは端的には「使用者の指揮命令下に置かれた時間」と解され"
          date="2023.05.12"
        />
        <ColumnBtnList
          href={routeParam25}
          imgId="c0049.jpg"
          label="欠勤とは？気になる欠勤控除の計算方法や残業代との関係は"
          text="国によるワーク・ライフ・バランスや働き方改革の施策や、新型コロナウイルス感染症の蔓延を受け、労働者の雇用形態は多様化の一途をたどっています。会社側でも、このような世の流れを受"
          date="2023.04.28"
        />
        <ColumnBtnList
          href={routeParam26}
          imgId="c0048.jpg"
          label="勤務間インターバル制度とは？制度やメリットについて解説"
          text="ワーク・ライフ・バランスや働き方改革の推奨、そして新型コロナウイルス感染症蔓延の影響により、社員の働き方は多様化しています。そのような中で新たに注目されている働き方の一つが"
          date="2023.04.14"
        />
        <ColumnBtnList
          href={routeParam27}
          imgId="c0047.jpg"
          label="高度プロフェッショナル制度とは？仕組みや手続きについて解説"
          text="日本の雇用環境現場では、多くの場合、働いた時間に対して賃金を支払う方法が採用されており、労働者は予め決められた時間内は会社の指揮命令を受けて、期待される労務を提供すること"
          date="2023.03.29"
        />
        <ColumnBtnList
          href={routeParam28}
          imgId="c0046.jpg"
          label="働き方改革における「産業医機能の強化」とは？詳しく解説"
          text="時間外労働の上限規制や年次有給休暇5日取得義務等、罰則を設けた法改正に注目が集まりがちの働き方改革ではありますが、働き方改革の中には産業医・産業保健機能の強化も重要な施策とし"
          date="2023.03.14"
        />
        <ColumnBtnList
          href={routeParam29}
          imgId="c0045.jpg"
          label="中小企業も適用に！月60時間超残業の割増賃金率引き上げについて"
          text="2023年4月以降、中小企業でも月に60時間を超える時間外労働を行う従業員に対して割増率が引き上げられます。実務上、既に大企業は対象とされていますが、中小企業にも拡大されます。今回は"
          date="2023.02.27"
        />
        <ColumnBtnList
          href={routeParam30}
          imgId="c0044.jpg"
          label="クラウド型勤怠システムの導入のすすめ。メリットと選び方を伝授"
          text="労務管理を進めていく中で、最大の懸念点は従業員数が増えるごとに管理が煩雑にならざるを得ないことです。そのための一つの緩和策として、クラウド型勤怠システムの導入が挙げられます。"
          date="2023.02.10"
        />
        <ColumnBtnList
          href={routeParam31}
          imgId="c0043.jpg"
          label="雇用形態とは？働き方の種類や違いをわかりやすく解説！"
          text="国によるワーク・ライフ・バランスや働き方改革の施策や、新型コロナウイルス感染症の蔓延を受け、労働者の雇用形態は多様化の一途をたどっています。会社側でも、このような世の流れ"
          date="2023.01.27"
        />
        <ColumnBtnList
          href={routeParam32}
          imgId="c0041.jpg"
          label="介護休暇とは？介護休業との違いや基本的な内容を解説"
          text="介護休暇とは、家族を介護するために取得する休暇として、ワーク・ライフ・バランスや働き方改革が推奨される中、効果的な制度として打ち出されています。"
          date="2023.01.13"
        />
        <ColumnBtnList
          href={routeParam33}
          imgId="c0040.jpg"
          label="看護休暇とはどんな制度？取得条件や休暇日数など解説します"
          text="看護休暇とは、幼い子供を育てる労働者が取得することのできる非常にありがたい休暇制度です。ワーク・ライフ・バランスや働き方改革を受けて、各企業には"
          date="2022.12.27"
        />
        <ColumnBtnList
          href={routeParam34}
          imgId="c0035.jpg"
          label="代休とは？付与する際に気を付けるべき点を解説します"
          text="労働者保護の観点から労働基準法では毎週少なくとも1回の休日を設けるように規定しています。また、起算日を明らかにすれば4週間に4日以上の休日を与える"
          date="2022.12.14"
        />
        <ColumnBtnList
          href={routeParam35}
          imgId="c0037.jpg"
          label="通勤手当とは？基本的な考え方と支給の形態について解説"
          text="コロナ禍を経て、働き方が変革を迎えています。これまで毎日出勤をしながら対面業務一択であった企業も特定のエッセンシャルワーカーを除き、テレワークを採用し"
          date="2022.11.28"
        />
        <ColumnBtnList
          href={routeParam36}
          imgId="c0036.jpg"
          label="代休と振替休日の違いは？基本知識や注意しなければならないこと"
          text="突発的な顧客対応など、労働者であればやむを得ず休日に労働せざるを得ないことも少なくありません。また、会社としても管理職のみで対応が困難な場合、"
          date="2022.11.11"
        />
        <ColumnBtnList
          href={routeParam37}
          imgId="c0039.jpg"
          label="「育児短時間勤務（時短）」「育児時間」とは？制度を解説します"
          text="ワーク・ライフ・バランスや働き方改革の流れを受け、育児をしながら働く労働者は増加の一途をたどっています。今回は、育児と仕事を両立するための"
          date="2022.10.28"
        />
        <ColumnBtnList
          href={routeParam38}
          imgId="c0038.jpg"
          label="育児休暇とは？育児休業との違いや気になる助成金の紹介"
          text="仕事とプライベートの両立による充実した健康的な生活を推奨する「ワーク・ライフ・バランス」が打ち出される中で新設された「育児休暇」"
          date="2022.10.14"
        />
        <ColumnBtnList
          href={routeParam39}
          imgId="c0042.jpg"
          label="2022年4月から順次施行開始！「改正育児・介護休業法」とは"
          text="育児・介護休業法の内容が改正され、令和4年4月より順次新制度が適用される運びとなりました。時代の流れに沿って幾度となく改正を繰り返している同法律"
          date="2022.09.27"
        />
        <ColumnBtnList
          href={routeParam40}
          imgId="c0034.jpg"
          label="有給休暇がもらえない？休職時における有給休暇の付与について"
          text="負傷や疾病などの理由で労務の提供が困難となり、休職となるケースは決して珍しくありません。しかし、休職は労働基準法上定めがなく、会社独自に定めて"
          date="2022.09.12"
        />
        <ColumnBtnList
          href={routeParam41}
          imgId="c0033.jpg"
          label="休業と休暇は何が違う？意外に知らない基礎知識を解説します"
          text="働き方改革の施行とコロナ禍があいまって、労務管理上も休業、休暇などの定義が曖昧となっているケースを目の当たりにします。今回は休業と休暇の違い"
          date="2022.08.26"
        />
        <ColumnBtnList
          href={routeParam42}
          imgId="c0032.jpg"
          label="休憩時間のルールとは？労働基準法の規定やトラブル事例を紹介"
          text="労働者から一定時間以上労務の提供を受けるにあたっては、健康への配慮や労働生産性の向上のためにも労働時間の途中に休憩を設けなければなりません。休憩"
          date="2022.08.10"
        />
        <ColumnBtnList
          href={routeParam43}
          imgId="c0031.jpg"
          label="深夜労働とは？何時から？割増賃金や法律について解説します"
          text="長時間労働が問題視されている中、削減が求められている内容の一つに「深夜労働」が挙げられます。深夜労働は、夜間という一般的には休息時間に充てる者"
          date="2022.07.28"
        />
        <ColumnBtnList
          href={routeParam44}
          imgId="c0001.jpg"
          label="働き方改革で変わる労働基準法。今後予定している法改正について解説"
          text="労働条件の最低基準を定める法律として労働基準法があります。この法律は労働者を1人でも雇入れた際には強制的に課される法律で、最低基準を"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam45}
          imgId="c0002.jpg"
          label="勤怠管理とは？管理をする必要性や管理体制の整備について解説"
          text="勤怠管理は、会社の規模や業種にかかわらず、会社内で必ず行わなければならないものです。ワーク・ライフ・バランスや働き方改革、そして新型コロナウイルス感染症流行拡大の影響により"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam46}
          imgId="c0003.jpg"
          label="勤怠管理システムを導入したい！メリットや気を付けるべき点とは"
          text="ワーク・ライフ・バランスや働き方改革の流れを受け、雇用形態が多様化し、社員それぞれの状況に適した形で働くことのできる環境づくりが求められるようになりました。さらに、新型コロナウイルスの流行を受け、自粛要請に伴い在宅勤務を強いられることになった企業も多いことでしょう。"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam47}
          imgId="c0004.jpg"
          label="働き方改革とは？勤怠管理において重要な労働時間に関する内容を紹介"
          text="「働き方改革」という言葉を一度は耳にしたことがあるのではないでしょうか。ワーク・ライフ・バランスや多様で柔軟性のある働き方を実現するため、"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam48}
          imgId="c0005.jpg"
          label="所定労働時間とは？法定労働時間との違いをしっかり覚えよう"
          text="昨今では、長時間労働にまつわる問題が深刻化していることを受け、国はワーク・ライフ・バランスや働き方改革などの対策法を広く推奨しています。この影響を受け、各会社に対しても社員の労働時間の正しい管理についても厳しい管理体制を敷くこと"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam49}
          imgId="c0006.jpg"
          label="通常の労働時間制（固定労働時間制度）と変形労働時間制とは？"
          text="通常の労働時間制、フレックスタイム制、裁量労働制など、我が国には働き方の多様化に対応した変則的な労働時間制が複数設けられています。どの制度を活用することでメリットの最大化を図ることができるかは、前提としておさえておくべき論点が明確化されていることで正しい選択が可能となります"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam50}
          imgId="c0007.jpg"
          label="通常の労働時間制（固定労働時間制度）を採用するメリットや注意点は？"
          text="現在の日本での働き方は月曜日から金曜日まで同じ時間帯に働く働き方（固定労働時間制、以下通常の労働時間制）が多く採用されています。"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam51}
          imgId="c0008.jpg"
          label="多様な働き方、変形労働時間制を採用するメリットや注意点は？"
          text="働き方改革により時間、場所、契約形態の多様化が促進され、一部のエッセンシャルワーカーを除き、「顔が見えない」中での労務管理も一"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam52}
          imgId="c0009.jpg"
          label="テレワークと通常時の業務における勤怠管理の必要性と問題点とは？"
          text="新型コロナウイルス感染拡大防止の観点からテレワークの定着が一気に加速しました。しかし、テレワーク一択というわけにはいかず、通常の対面業務とのハイブリッド型"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam53}
          imgId="c0010.jpg"
          label="裁量労働制とはどんな制度？内容やメリット・デメリットを解説"
          text="一般的な固定労働時間制とは異なる制度として「裁量労働制」があります。新入社員からベテラン社員まで画一的に導入することは困難ですが、"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam54}
          imgId="c0011.jpg"
          label="就業時間とは？概念や設定方法、賃金計算の仕方について解説"
          text="近年、働き方の見直しを行う動きが活性化しています。そのうえで就業時間や労働時間など、ルールをしっかりと理解することが必須となります。"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam55}
          imgId="c0012.jpg"
          label="年次有給休暇の付与に必要な出勤率は8割？「出勤率」って何？"
          text="有給休暇を付与するにあたって法律上避けて通ることが出来ない部分として「出勤率」の問題があります。これは端的には労働者がどれだけ会社に対して労働力を"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam56}
          imgId="c0013.jpg"
          label="残業に必要な36協定、「知らなかった」では済まされない基礎知識"
          text="社会には様々な業種があり、業務内容等会社によって多様です。ですが、ほとんどの企業では繁忙期など、通常の労働時間では業務が間に合わない場合に「残業（時間外労働）」"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam57}
          imgId="c0014.jpg"
          label="客先常駐での労働時間の管理や36協定の扱いはどう対応すべき？"
          text="IT業界では比較的一般的とされる客先常駐ですが、雇用契約を交わしている会社で勤務する通常の社員とは働き方が異なるため、労務管理をどのように"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam58}
          imgId="c0015.jpg"
          label="管理監督者とは？正しい定義と管理職との違いを詳しく解説"
          text="社員の労働時間に関する問題は、これまでにもさまざまな形で労使トラブルが相次ぎ、社会問題化し続けて久しい状況にあります。このような中、法改正によ"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam59}
          imgId="c0016.jpg"
          label="常駐先企業と自社、どっちの就業規則が適用される？注意点は？"
          text="所属先の企業とは別の場所で働く客先常駐社員の場合、所属先・常駐先のどちらの会社のルールに従うべきなのか、迷うケースが多くみられるのが現状です"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam60}
          imgId="c0017.jpg"
          label="法定休日とは？法定外休日との違いなど、休日に関する知識を公開"
          text="会社にとって、そして会社で働く社員にとって、最も重要な雇用に関する内容に「休日」が挙げられます。社員には一定の頻度で休日を与えなければならない"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam61}
          imgId="c0018.jpg"
          label="フレックスタイム制はどんな制度？導入のメリットとポイント"
          text="働き方改革の施行により多様な働き方が推進されています。子の養育や親の介護と並行して職業生活を営むことに対する社会的な要請は今後益々増えてくるもの"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam62}
          imgId="c0019.jpg"
          label="自由度の高い新しい働き方「スーパーフレックス制度」とは？"
          text="働き方改革の施行以降、通常の固定労働時間制だけでなく、多様な働き方が注目されています。多様な働き方の目的の一つとして労働者の職業生活と家庭生活と"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam63}
          imgId="c0020.jpg"
          label="連勤は何日まで可能？法律で決まった上限日数や連勤の問題点とは"
          text="1週間7日という概念はどの事業所であっても変わることはありませんが、連続勤務（以下、連勤）については各事業所によって頻度や程度の差があります。"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam64}
          imgId="c0021.jpg"
          label="年次有給休暇とは？付与の条件や日数、時期などを詳しく解説"
          text="日本の労働法制上、休暇に対して賃金支払いが義務付けられているものは労働基準法で規定されている年次有給休暇のみです。例えば育児介護休業法で規定されている"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam65}
          imgId="c0022.jpg"
          label="有給休暇の取得義務化！企業がするべき対応やポイントとは"
          text="2019年より「有給休暇の取得義務化」が施行されたことで、各企業は社員が安心して有給休暇を取得できるような環境づくりが急務とされています。"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam66}
          imgId="c0023.jpg"
          label="アルバイトやパートにも有給休暇がある！付与日数や条件は？"
          text="従業員を雇用する際に、避けて通ることが出来ない法律に労働基準法があります。そして、労働者としても注目が集まる部分として有給休暇が"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam67}
          imgId="c0024.jpg"
          label="時間単位と半日単位の有給休暇とは？制度内容と導入方法を解説"
          text="会社で働く労働者にとっても注目度の高い制度の一つに有給休暇が挙げられます。有給休暇は本来、暦日を単位として取得することが原則とされていますが、"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam68}
          imgId="c0025.jpg"
          label="こんな時に使いたい！時間単位の有給休暇の活用事例と注意事項"
          text="有給休暇の本来の趣旨は賃金支払いが保障された状態で心身のリフレッシュを図り、更なる就労意欲の向上にあると考えます。しかし、業種によっては有給休暇"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam69}
          imgId="c0026.jpg"
          label="特別休暇とは？基礎知識や種類、他の休暇との違いを紹介します"
          text="労働基準法で定める年次有給休暇は労働法制上唯一賃金支払い義務を課しており、賃金を支払わないという選択肢はありません。近年、労働基準法で定める"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam70}
          imgId="c0027.jpg"
          label="残業とは何か？残業の定義や上限時間などを解説します"
          text="仕事には、残業がつきものといっても過言ではないでしょう。しかし、残業にまつわるさまざまな労使トラブルは後を絶たず、深刻な社会問題化していることも"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam71}
          imgId="c0028.jpg"
          label="みなし残業とは？間違えやすいみなし労働時間制との違いまで解説"
          text="残業時間に関するトラブルは後を絶たず、依然として社会問題化しています。しかし、社員の残業時間の把握や残業代の計算作業は正確性が必要とされ、時間が"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam72}
          imgId="c0029.jpg"
          label="中小企業も対象？時間外労働の上限時間や割増賃金率の引き上げ"
          text="長時間労働問題に対応するため、昨今では時間外労働に関するさまざまな法改正が行われています。特に時間外労働の上限時間や、割増賃金率の引き上げについては、"
          date="2022.04.15"
        />
        <ColumnBtnList
          href={routeParam73}
          imgId="c0030.jpg"
          label="試用期間中に残業することに！残業代は支払われますか？"
          text="本採用を行う前に試用期間を設けることは一般的に用いられている労務管理手法ですが、試用期間中に残業を命じても問題ないかという疑問が生じます。試用期間前後を"
          date="2022.04.15"
        />
        {/**
                 <ColumnBtnList
                 href="/column/details/C0063/"
                 imgId="c0063.jpg"
                 label="勤怠管理と給与計算の連携について。なぜ連携した方がいいの？留意すべきポイントとは"
                 text="一定以上の規模になると勤怠管理と給与計算は密接に関連することとなります。もちろん連携しない状態であっても運用自体は可能ですが、相互に連携することで相互の事務の簡素化合理化を進めることが"
                 date="2023.04.15"
                 />
                 <ColumnBtnList
                 href="/column/details/C0064/"
                 imgId="c0064.jpg"
                 label="コロナ禍で変わった労働環境。有給取得率や残業は？"
                 text="コロナが本格化し出した2020年から3年の時を経て、労務管理手法については大きな変化が見られました。労務管理手法の変化は我が国の最も多くを占める被雇用者に対しても直接的に影響が及ぶ部分で被"
                 date="2023.04.15"
                 />
                 <ColumnBtnList
                 href="/column/details/C0065/"
                 imgId="c0065.jpg"
                 label="年次有給休暇管理簿とは？対象者、罰則、作成方法、保存期間などについて解説"
                 text="2019年4月1日以後、企業規模を問わず、年10日以上有給休暇を付与される労働者に対して付与された日から1年以内に5日の時季指定が義務化されています。この時季指定義務と併せて「年次有給休暇管理簿」"
                 date="2023.04.15"
                 />
                 <ColumnBtnList
                 href="/column/details/C0066/"
                 imgId="c0066.jpg"
                 label="ハイブリッドワークとは？メリットやデメリット、成功させるポイントについて解説！"
                 text="多様な働き方が尊重される時代が到来しており、法律に明記のあるフレックスタイム制や裁量労働制等だけでなく、法律に明記はないものの、徐々に採用されつつある「ハイブリッドワーク」にも注目が"
                 date="2023.04.15"
                 />

                 */}
      </section>
    </Wrapper>
  )
}

export default ColumnAllLists
