import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'

const StyledLink = styled(Link)`
  padding: 7px;
  border-radius: 5px;
  border: solid 3px transparent;
  display: flex;
  margin-bottom: 10px;
  &: hover {
    border-color: #77d7c9;
    text-decoration: underline solid rgba(255, 255, 255, 0);
  }
  .img-wrap {
    width: 200px;
    min-width: 200px;
    height: 100px;
    overflow: hidden;
    margin-right: 15px;
    img {
      width: 110%;
      height: auto;
    }
  }
  .txt-wrap {
    h2 {
      font-size: 18px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      margin-bottom: 6px;
    }
    p {
      color: #505050;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    span {
      color: #ababab;
    }
  }
`

type Props = {
  /** クラス名 */
  className?: string
  /** リンク先 */
  href: string
  /** 画像 */
  imgId: string
  /** タイトル */
  label: string
  /** 本文 */
  text: string
  /** 日付 */
  date: string
}

/**
 * コラム用リスト型ボタンリンクコンポーネント
 * @param props
 * @constructor
 */
const ColumnBtnList = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <StyledLink className={className} to={props.href}>
      <div className="img-wrap">
        {/** TODO 川上申し送り事項：▼記事ごとに指定されて画像がここに表示されます */}
        <img
          src={`/images/column/details/${props.imgId}`}
          alt="キンクラコラム"
        />
      </div>
      <div className="txt-wrap">
        {/** TODO 川上申し送り事項：▼記事のタイトルがここに表示されます */}
        <h2>{props.label}</h2>
        {/** TODO 川上申し送り事項：▼記事の本文がここに表示されます */}
        <p>{props.text}</p>
        {/** TODO 川上申し送り事項：▼記事の投稿日がここに表示されます */}
        <span>{props.date}</span>
      </div>
    </StyledLink>
  )
}

export default ColumnBtnList
